<template>
<CSidebar fixed :minimize="minimize" :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
  <CSidebarBrand class="d-md-down-none" style="text-decoration: none;" to="/">
    <table>
      <tr>
        <td>
          <img src="/astronaut.png" width="35" height="35">
        </td>
        <td width="5"></td>
        <td>
          <div style="font-size: 20px; text-shadow: 2px 2px black;">
          Command Center
        </div>
        </td>
      </tr>
    </table>
  </CSidebarBrand>
  <CRenderFunction flat :content-to-render="$options.nav" />
  <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
</CSidebar>
</template>

<script>
import nav from './_nav'
export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>
