export default [{
  _name: 'CSidebarNav',
  _children: [
    {
      _name: 'CSidebarNavTitle',
      _children: ['Services']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Calls Radar',
      to: '/calls-radar',
      icon: 'cil-rss'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Files',
      to: '/files',
      icon: 'cil-description'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Keyword Search',
      to: '/keyword-search',
      icon: 'cil-search'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Number Classifier',
      to: '/number-classifier',
      icon: 'cil-user-unfollow'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Profiles',
      to: '/profiles',
      icon: 'cil-contact'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'SMS Filter',
      to: '/sms-filter',
      icon: 'cil-bank'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'SMS Fingerprint',
      to: '/sms-fingerprint',
      icon: 'cil-fingerprint'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'SMS Radar',
      to: '/sms-radar',
      icon: 'cil-rss'
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['Reputation']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Domain Reputation',
      to: '/domain-reputation',
      icon: 'cil-badge'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Number Reputation',
      to: '/reputation',
      icon: 'cil-badge'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'SMS Reputation',
      to: '/sms-reputation',
      icon: 'cil-badge'
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['Platform']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Auth',
      to: '/auth',
      icon: 'cil-https'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Developer Tools',
      to: '/developer-tools',
      icon: 'cil-code'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Services',
      to: '/services',
      icon: 'cil-lan'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Status Page',
      to: '/status-page',
      icon: 'cil-heart'
    }
  ]
}]
