<template>
<CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
  <template #toggler>
    <CHeaderNavLink>
      <div class="c-avatar">
        <img :src="accountPic" class="c-avatar-img " />
      </div>
    </CHeaderNavLink>
  </template>
  <CDropdownHeader tag="div" class="text-center" color="light">
    <strong>{{ accountName }}</strong><br>
    {{ accountEmail }}
  </CDropdownHeader>
  <!-- <CDropdownItem>
    <CIcon name="cil-user" /> Profile
  </CDropdownItem>
  <CDropdownItem>
    <CIcon name="cil-settings" /> Settings
  </CDropdownItem>
  <CDropdownItem>
    <CIcon name="cil-file" /> My Models
    <CBadge color="primary" class="mfs-auto">5</CBadge>
  </CDropdownItem>
  <CDropdownDivider /> -->
  <CDropdownItem @click="handleLogout">
    <CIcon name="cil-lock-locked" /> Logout
  </CDropdownItem>
</CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      accountPic: "",
      accountName: "",
      accountEmail: ""
    }
  },
  mounted() {
    var accountInfo = RobocallsAI.shared().accountInfo()
    this.accountPic = accountInfo.picture;
    this.accountName = accountInfo.name;
    this.accountEmail = accountInfo.email;
  },
  methods: {
    handleLogout() {
      RobocallsAI.shared().logout();
    }
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
