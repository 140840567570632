<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">© 2019 <a href="https://www.teltech.co">TELTECH.CO</a></span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
